import { default as _91_46_46_46page_93xJ6y8qrzkDMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/public-portal/pages/[...page].vue?macro=true";
import { default as bedankt0NVvNrtd1VMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/public-portal/pages/bedankt.vue?macro=true";
import { default as indexXuAEUIYk5pMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/public-portal/pages/index.vue?macro=true";
import { default as wifi_45connectbV04sbkaPjMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/public-portal/pages/wifi-connect.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93xJ6y8qrzkDMeta?.name ?? "page",
    path: _91_46_46_46page_93xJ6y8qrzkDMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93xJ6y8qrzkDMeta || {},
    alias: _91_46_46_46page_93xJ6y8qrzkDMeta?.alias || [],
    redirect: _91_46_46_46page_93xJ6y8qrzkDMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/public-portal/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: bedankt0NVvNrtd1VMeta?.name ?? "bedankt",
    path: bedankt0NVvNrtd1VMeta?.path ?? "/bedankt",
    meta: bedankt0NVvNrtd1VMeta || {},
    alias: bedankt0NVvNrtd1VMeta?.alias || [],
    redirect: bedankt0NVvNrtd1VMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/public-portal/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: indexXuAEUIYk5pMeta?.name ?? "index",
    path: indexXuAEUIYk5pMeta?.path ?? "/",
    meta: indexXuAEUIYk5pMeta || {},
    alias: indexXuAEUIYk5pMeta?.alias || [],
    redirect: indexXuAEUIYk5pMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/public-portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: wifi_45connectbV04sbkaPjMeta?.name ?? "wifi-connect",
    path: wifi_45connectbV04sbkaPjMeta?.path ?? "/wifi-connect",
    meta: wifi_45connectbV04sbkaPjMeta || {},
    alias: wifi_45connectbV04sbkaPjMeta?.alias || [],
    redirect: wifi_45connectbV04sbkaPjMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/public-portal/pages/wifi-connect.vue").then(m => m.default || m)
  }
]